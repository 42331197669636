import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { CATEGORIES, PREDICTIONS } from '../../data/rawData';
import { COLORS } from '../../data/constants';
import styles from './Main.module.scss';

export function Main() {

    const [selectedPrediction, setSelectedPrediction] = useState(null)
    const [previousPrediction, setPreviousPrediction] = useState(null)
    const [previousOriginalPosition, setPreviousOriginalPosition] = useState(null)
    const [dataset, setDataset] = useState(null)
    const [selectedLabel, setSelectedLabel] = useState(null) //formatted {label: 'type', value: 'Innovation'}
    const [clickedLabel, setClickedLabel] = useState(null)
    const svgRef = useRef()

    //constants
    const periods = ['Now', '2025', '2030', '2040', '2050', 'Beyond'];
    const labels = [
        {
            name: 'frontier',
            display: 'Frontier',
        }, 
        {
            name: 'category',
            display: 'Topic'
        }, 
        {
            name: 'buzzBucket',
            display: 'Internet Buzz'
        }, 
        {
            name: 'type', 
            display: 'Type',
        }, 
        {
            name: 'period',
            display: 'Time'
        }
    ];
    const frontiers = Array.from(new Set(CATEGORIES.map(c => c.frontier)))
    const r1 = 5, r2 = r1/5
    const margin = {top: 0, bottom: 0, left: 150, right: 200}
    const width = 1000, height = 400;
    const stageCenter = [width/2, height/6]
    const highlightStorke = 5;

    //buzz scales
    const buzzLogScale = d3.scaleLog()
        .domain(d3.extent(CATEGORIES.map(c => c.buzz)))
        .range([0, 1])
    const buzzBucketScale = d3.scaleQuantize()
        .range([1, 2, 3])
    const frontierToColor = d3.scaleOrdinal()
        .domain(frontiers)
        .range(COLORS.frontiers)
    
    //helper to check if prediction is in selected group
    const isInSelectedGroup = (prediction) => prediction && selectedLabel && prediction[selectedLabel.name] === selectedLabel.value 
    
    //map dataset
    useEffect(() => {
        setDataset(PREDICTIONS.map(p => {
            const category = CATEGORIES.find(c => c.id === p.categoryId)
            return {
                ...p, //id, categoryId, period, text
                frontier: category.frontier,
                category: category.name,
                buzz: category.buzz,
                buzzBucket: buzzBucketScale(buzzLogScale(category.buzz)),
                type: category.type,
                emoji: category.emoji,
            }
        }))
    }, [])

    //initialize visualization/simulation
    const sim = d3.forceSimulation()
    useEffect(() => {
        if(dataset) {
            //clear selections
            setSelectedLabel(null)
            setSelectedPrediction(null)

            // svg setup
            const svg = d3.select(svgRef.current)
                // .attr('width', margin.left + width + margin.right)
                // .attr('height', margin.top + height + margin.bottom)
                .attr('viewBox', `0 0 ${margin.left + width + margin.right} ${margin.top + height + margin.bottom}`)
            svg.selectAll('.background')
                .data([true])
                .join('rect')
                .attr('class', 'background')
                .attr('width', margin.left + width + margin.right)
                .attr('height', margin.top + height + margin.bottom)
                .attr("fill", COLORS.background)
                .on('click', () => setSelectedPrediction(null))

            //scales
            const xScale = d3.scaleBand()
                .domain(periods)
                .range([0, width])
            const yScale = d3.scaleLog()
                .domain(d3.extent(CATEGORIES.map(d => d.buzz)))
                .range([-height, 0]) 
            const wallArray = Array.from(Array(Math.ceil((2*height+8*r2)/(r2*2))).keys())
            const wallScale = d3.scaleLinear()
                .domain(d3.extent(wallArray))
                .range([-height, height+8*r2])
            const floorArray = Array.from(Array(Math.ceil((width+8*r2)/(r2*2))).keys())
            const floorScale = d3.scaleLinear()
                .domain(d3.extent(floorArray))
                .range([-4*r2, width+4*r2])
            const sizeScale = d3.scaleLinear()
                .domain(d3.extent(buzzBucketScale.range()))
                .range([10, 20])

            //initial nodes 
            const nodes = dataset.map(p => ({
                data: p,
                x: xScale(p.period) + Math.random()*xScale.bandwidth(),
                y: yScale(p.buzz)
            }))
            //wall circles
            wallArray.forEach(d => {
                nodes.push({fx: -2*r2-30, fy: wallScale(d)})
                nodes.push({fx: -3*r2-30, fy: wallScale(d)-r2})
                nodes.push({fx: -4*r2-30, fy: wallScale(d)})
                nodes.push({fx: width + 2*r2 +30, fy: wallScale(d)})
                nodes.push({fx: width + 3*r2 +30, fy: wallScale(d)-r2})
                nodes.push({fx: width + 4*r2 +30, fy: wallScale(d)})
            })
            //floor circles
            floorArray.forEach(d => {
                nodes.push({fx: floorScale(d), fy: height+2*r2 })
                nodes.push({fx: floorScale(d)-r2, fy: height+3*r2 })
                nodes.push({fx: floorScale(d), fy: height+4*r2 })
            })

            const nodesWithData = nodes.filter(n => n.data)

            //create main force simulation
            sim.nodes(nodes)
                .alphaTarget(0.3) //never ends 
                .velocityDecay(0.25) //medium friction
                .force("y",  d3.forceY(height).strength(n => n.data ? 0.01 : 0))
                .force("collide", d3.forceCollide().radius(n => n.data ? 1.2*sizeScale(n.data.buzzBucket) : r1).strength(.25).iterations(10))
                .on('end', () => console.log('MAIN SIM ENDED'))

                
            //define gradients
            svg.selectAll('defs').data([true]).join('defs')
                .selectAll('radialGradient').data(nodesWithData).join('radialGradient')
                    .attr('id', (d) => `grad_${d.data.id}`)
                    .selectAll("stop")
                    .data( d =>  [
                        {offset: "0%", color: d.data.type === "Development" ? COLORS.background : frontierToColor(d.data.frontier)} ,
                        {offset: "50%", color: d.data.type === "Development" ? COLORS.background : frontierToColor(d.data.frontier)} ,
                        {offset: "100%", color: frontierToColor(d.data.frontier) }
                    ])
                    .join("stop")
                        .attr("offset", d => d.offset)
                        .attr("stop-color", d => d.color);

            //render circles
            const circlesGroup = svg.selectAll('g')
                .data([true])
                .join('g')
                .attr('transform', `translate(${margin.left}, ${margin.top})`)
            circlesGroup.selectAll('circle')
                    .data(nodes)
                    .join('circle')
                        .attr('class', n => n.data ? `ball` : 'wall')
                        .attr('r', n => n.data ? sizeScale(n.data.buzzBucket) : r2) 
                        .attr('fill', (n) => n.data ? `url(#grad_${n.data.id})` : 'none')
                        .attr('cx', n => n.x)
                        .attr('cy', n => n.y)
                        .attr('display', n => n.data ? '' : 'none')
                        .on('click', (e, v) =>{
                            e.preventDefault()
                            setSelectedPrediction(v.data)
                        })
            circlesGroup.selectAll('.emoji')
                .data(nodes)
                .join('text')
                    .text(n => n.data? n.data.emoji : '')
                    .attr('x', n => n.data ? n.data.x : null)
                    .attr('y', n => n.data ? n.data.y : null)
                    .attr('text-anchor', 'middle')
                    .attr('dominant-baseline', 'middle')
                    .attr('class', styles.emoji)
                    .attr('font-size', n => n.data ? sizeScale(n.data.buzzBucket) : 0)
                                
           const balls = svg.selectAll('.ball')
           const emojis = svg.selectAll('text')

            //update simulation
            sim.on('tick', () => {
                balls
                    .attr('cx', n => n.x)
                    .attr('cy', n => n.y)
                emojis
                    .attr('x', n => n.x)
                    .attr('y', n => n.y)
                })
        }
    }, [svgRef, dataset])

    //update label highlight
    useEffect(() => {
        const svg = d3.select(svgRef.current)
            svg.selectAll('.ball')
                .attr('stroke', n => isInSelectedGroup(n.data) ? COLORS.sphereLight : 'none')
                .attr('stroke-width', n => isInSelectedGroup(n.data) ? highlightStorke : 'none')
    }, [selectedLabel])

    //repeating simulations:
        //float new ball up
    const up = d3.forceSimulation().alphaDecay(0) //never ends and maintains "heat"
        // .alphaTarget(0.3)
        // .velocityDecay(0.1)
    //float old ball down
    const down = d3.forceSimulation()//.alphaDecay(0) //never ends and maintains "heat"
    .alphaTarget(0.3)
    .velocityDecay(0)
    
    //update prediction highlight
    useEffect(() => {
        const svg = d3.select(svgRef.current)
        const balls = svg.selectAll('.ball')
                   
        //deselect all selected metadata labels (clean start)...
        // (if new selection )
        if (!isInSelectedGroup(selectedPrediction)) setSelectedLabel(null)

        //if previous prediction, float old ball down
        if (balls && previousPrediction && previousOriginalPosition) {
            const ballsData = balls.data()
            const oldBallNode = ballsData.filter(d => previousPrediction && d.data.id === previousPrediction.id)
            down.force('back', d3.forceCenter(...previousOriginalPosition).strength(0.7))
                // .force('unstick', d3.forceCollide().radius(r1*1).strength(0.5))
                .nodes(oldBallNode)
            sim.nodes(ballsData)
        }

        if (selectedPrediction) {
            //update new "old" prediction and location for ball about to float up
            const ballsData = balls.data()
            const selectedBallNode = ballsData.filter(d => selectedPrediction && d.data.id === selectedPrediction.id)
            setPreviousOriginalPosition([selectedBallNode[0].x, selectedBallNode[0].y])
            setPreviousPrediction(selectedBallNode[0].data)
            
            up.force('up', d3.forceCenter(...stageCenter).strength(0.7))
            .force('unstick', d3.forceCollide().radius(r1*1).strength(0.5))
                .nodes(selectedBallNode)
            
        }

        // console.log("sim nodes:", sim.nodes().length, "\nup nodes:", up.nodes().length,"\ndown nodes:", down.nodes().length)

    }, [selectedPrediction])
    
    //legend components and helping arrays
    const FrontierLegend = ({color, label}) => {
        return <div 
            className={`${styles.legend_cell} ${selectedLabel && selectedLabel.value === label ? styles.selected_legend : ''}`}
            onMouseOver={() => {

                setSelectedLabel({name: 'frontier', value: label})
            }} 
            onMouseOut={() => { 
                if(!clickedLabel) setSelectedLabel(null)
                else setSelectedLabel(clickedLabel)
            }}     
            onClick={(e) => {
                console.log(e.target.getAttribute('class'))
                if (clickedLabel && selectedPrediction && selectedPrediction.frontier !== label) {setSelectedPrediction(null)}
                setSelectedLabel({name: 'frontier', value: label})
                setClickedLabel({name: 'frontier', value: label})
            }}    
            >
            <div style={{
                backgroundColor: color, 
                display: 'inline-block', 
                borderRadius: '50%', 
                width: '20px', 
                height: '20px', 
            }} />
            <div className={styles.legend_label}>{label}</div>
        </div>
    }
    const frontiersAndColors = frontiers.map((f, i) => (
        {
            color: COLORS.frontiers[i], 
            label: f
        }
    ))
    const SizeLegend = ({size, label, bucket}) => {
        return <div 
        className={`${styles.legend_cell} ${selectedLabel && selectedLabel.value === bucket ? styles.selected_legend : ''}`}
        onMouseOver={() => {
            setSelectedLabel({name: 'buzzBucket', value: bucket})
        }} 
        onMouseOut={() => { 
            console.log("left size  cell")
            if(!clickedLabel) setSelectedLabel(null)
            else setSelectedLabel(clickedLabel)
        }}     
        onClick={() => {
            if (clickedLabel && selectedPrediction && selectedPrediction.buzzBucket !== bucket) setSelectedPrediction(null)
            setSelectedLabel({name: 'buzzBucket', value: bucket})
            setClickedLabel({name: 'buzzBucket', value: bucket})
        }}    
        >
        <div style={{
            backgroundColor: 'white', 
            display: 'inline-block', 
            borderRadius: '50%', 
            width: size, 
            height: size, 
        }} />
        <div className={styles.legend_label}>{label}</div>
    </div>
    }
    const legendSizes = [{size: '15px', label: "Little buzz", bucket: 1}, {size: '20px', label: "More buzz", bucket: 2}, {size: '25px', label: "Lots of buzz!", bucket: 3}]

    //render block
    return <div className={styles.main_wrapper} onClick={(e) => {
        // when clicking on something that isn't a ball or a metadata value, deselect prediction
        if (e.target.getAttribute('class') !== 'ball' 
            && !e.target.getAttribute('class').includes(styles.period_button)
            && !e.target.getAttribute('class').includes(styles.metadata_value)
            && !e.target.getAttribute('class').includes(styles.legend_cell)
            && !e.target.getAttribute('class').includes(styles.legend_label)
        ) {
            setSelectedPrediction(null)  
            setSelectedLabel(null)    
            setClickedLabel(null)
        }    
    }
        }>
        <svg className={styles.svg_wrapper} ref={svgRef}></svg>
        { selectedPrediction && <div className={styles.text_container}>
            {selectedPrediction.text}
        </div>}
        {selectedPrediction && <div className={styles.metadata_container}> 
            {labels.map(l => <>
                <div className={styles.metadata_label} key={`metadata_label_${l}`}>{l.display}:</div>
                <button 
                    key={`metadata_value_${l}`}
                    type='button'
                    className={`${styles.metadata_value} ${selectedLabel && selectedLabel.name === l.name ? styles.selected : ''}`} 
                    onMouseOver={() => setSelectedLabel({name: l.name, value: selectedPrediction[l.name]})} 
                    onMouseOut={() => { 
                        if(!clickedLabel) setSelectedLabel(null)
                        else setSelectedLabel(clickedLabel)
                    }}     
                    onClick={() => {
                        setSelectedLabel({name: l.name, value: selectedPrediction[l.name]})
                        setClickedLabel({name: l.name, value: selectedPrediction[l.name]})
                    }}            
                >
                    {
                        (l.name === 'buzzBucket') ? '🔥'.repeat(selectedPrediction.buzzBucket) :
                        (l.name === 'category') ? `${selectedPrediction.emoji} ${selectedPrediction[l.name]}` :
                        selectedPrediction[l.name]
                    }
                </button>
            </>)}
        </div>} 
        <div className={styles.period_menu}>
            { svgRef.current && periods.map(d => 
                <button 
                    type='button' 
                    className={`${styles.period_button} ${selectedLabel && selectedLabel.value === d ? styles.selected : ''}`} 
                    key={`period_button_${d}`}
                    onMouseOver={() => setSelectedLabel({name: 'period', value: d})} 
                    onMouseOut={() => { 
                        if(!clickedLabel) setSelectedLabel(null)
                        else setSelectedLabel(clickedLabel)
                    }}     
                    onClick={() => {
                        if (clickedLabel && selectedPrediction && selectedPrediction.period !== d) setSelectedPrediction(null)
                        setSelectedLabel({name: 'period', value: d})
                        setClickedLabel({name: 'period', value: d})
                    }}            
                >
                    {d}
                </button>
            )}
        </div>
        <div className={styles.legend_wrapper}>
            {/* <b>Interactive Legend</b> */}
            <div className={styles.legend_row}>
                {frontiersAndColors.map(d => <FrontierLegend key={`${d.label}_legend`} color={d.color} label={d.label} />)}
            </div>
            <div className={styles.legend_row}>
                {legendSizes.map(d => <SizeLegend key={`bucket_${d.size}_legend`} size={d.size} bucket={d.bucket} label={d.label}/>)}
            </div>
            <div className={`${styles.legend_row}`}>
                <div 
                className={`${styles.legend_cell} ${selectedLabel && selectedLabel.value === 'Development' ? styles.selected_legend : ''}`}
                onMouseOver={() => setSelectedLabel({name: 'type', value: 'Development'})} 
                onMouseOut={() => { 
                    if(!clickedLabel) setSelectedLabel(null)
                    else setSelectedLabel(clickedLabel)
                }}     
                onClick={() => {
                    if (clickedLabel && selectedPrediction && selectedPrediction.type !== 'Development') setSelectedPrediction(null)
                    setSelectedLabel({name: 'type', value: 'Development'})
                    setClickedLabel({name: 'type', value: 'Development'})
                }} 
                >
                    <div style={{
                        backgroundImage: `radial-gradient(${COLORS.background}, white)`, 
                        display: 'inline-block', 
                        borderRadius: '50%', 
                        width: '20px', 
                        height: '20px', 
                    }} />
                    <div className={styles.legend_label}>Development</div>
                </div>
                <div 
                className={`${styles.legend_cell} ${selectedLabel && selectedLabel.value === 'Innovation' ? styles.selected_legend : ''}`}
                onMouseOver={() => setSelectedLabel({name: 'type', value: 'Innovation'})} 
                onMouseOut={() => { 
                    if(!clickedLabel) setSelectedLabel(null)
                    else setSelectedLabel(clickedLabel)
                }}     
                onClick={() => {
                    if (clickedLabel && selectedPrediction && selectedPrediction.type !== 'Innovation') setSelectedPrediction(null)
                    setSelectedLabel({name: 'type', value: 'Innovation'})
                    setClickedLabel({name: 'type', value: 'Innovation'})
                }} 
                >
                <div style={{
                    backgroundColor: 'white', 
                    display: 'inline-block', 
                    borderRadius: '50%', 
                    width: '20px', 
                    height: '20px', 
                }} />
                    <div className={styles.legend_label}>Innovation</div>
                </div>

            </div>
        </div>
        
    </div>
}