import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

/* THOUGHTS
- Show the percentage of innovation/development of each frontier for every period
  This can be calculated by multiplying the buzz*(whether there is a prediction) for each category,
  - and add accross the frontier
  Use blue/green
*/

/* OTHER IDEA - WINNER
Have a pile of "prophecy crystal balls"
- they each have a watercolor/galaxy filter on the inside.
- Each a different pattern/color representing its frontier
- they will have an outer glow: blue if an innovation frontier, green if a development frontier
- the size of the ball represents the buzz (log scaled)
*/

/*

FINAL DESIGN:
- No glow, all crystal balls have gradient of a single color vs transparency.
- No connection between semantically similar predictions (tags take care of that)
- When a tag (metadata category) they possess is selected, they are highlighted with white stroke
- Color represents frontier
- Emoji represents category
- Size represents buzz bucket (3 or 5 different ones)
- Shape represents type (circle for development, hexagon for innovation)
  - Consider keeping all as circles, and clear vs opaque center for dev vs innovation
- x position represents time

- TODO
- [x] Create complete data set, where each prediction has a:
  - frontier
  - category
  - emoji
  - buzz
  - buzz bucket
  - type
  - period
  without having to go through it's parennt category first (will clean up code a ton!)
- [x] Implements single highlighted label
- [x] Encode frontier (color)
- [x] Encode category (emoji)
- [x] Encode buzz (size)
- [x] Encode type (fill/shape) //development is void inside, innovation is full
- [x] Time period filters at bottom
- [x] selecting 'buzz' label does not higlight all correct orbs (only the came category)
- [x] Legend at bottom left (everything but time period) (should it be interactive filter too?)
- [x] Decide if histogram at bottom right to show only when label is selected (no time)
- [x] Header (Add logo) 
- [x] Help modal (show only when you have not clicked anything at first, then have button to re-open/close)
- [] Fix simulation
- [x] Clean up text
- [x] favicon and title
- [x] Proofread
- [x] Host
*/
