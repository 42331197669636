import React from 'react';
import styles from './Modal.module.scss';

export function Modal({closeModal, showModal}) {
    return <div className={styles.modal_wrapper} style={{display: showModal ? 'flex' : 'none'}}>
        <button className={styles.close_modal_button} onClick={closeModal}>🗙</button>
        <div className={styles.modal_text}>
            <p>This is an interactive visualization of the <a href='https://docs.google.com/spreadsheets/d/1_xdns_UCtRNH9TWcxKYKa_HydlkZxbqCCYRfdxhUNpg/edit#gid=0' target="_blank">
                World Government Summit predictions for the future</a> in 7 "frontiers" (or areas for development and innovation).
            Think of each of the circles floating around as a crystal ball 🔮 to look into the future.
            </p>
            <p>Each "prediction" has its own <b>characteristics</b> (reference the interactive legend at the bottom):</p>
            <ul>
                <li>The <b>frontier</b> it belongs to (represented by color)</li>
                <li>Its <b>topic</b> within the frontier (represented by emojis)</li>
                <li>How much <b>internet buzz</b> ('🔥') there is currently around the topic </li>
                <li>Whether this prediction is a <b>development</b> (hollow circle) or an <b>innovation</b> (filled in)</li>
                <li>What <b>time</b> the prediction is for (further to the right is further in the future)</li>
            </ul>
            <p>To start, <b>click on a prediction ball</b> that looks interesting, any of them! You can filter them by time
                at the bottom too.</p> 
                
            <p>Once selected, the prediction will be highlighted, you can <b>read it</b> (top left), and find more information about it (top right).
            These interactive buttons will help you see how predictions interconnect! Do they share characteristics? Are they complete opposites? Can you find any predictions that are unique ✨
                (e.i. the only one of their topic, time, or type for a given frontier)?
            </p>
        </div>
        
        

    </div>
}