export const COLORS = {
    background: '#24214a',
    sphereLight: 'white',
    sphereDull: 'gray',
    innovationBlue: '#4c7ecf',
    developmentGreen: '#3da181',
    frontiers: [
        'indianred', //red
        "lightsalmon", //orange
        'lemonchiffon', //yellow
        'darkseagreen', //green
        'lightskyblue', //blue
        'mediumpurple', //purple
        'lightgrey' //gray
    ]
}