import React from 'react';
import styles from './Header.module.scss'
import logo from './wgs_logo.png'

export function Header ({openModal}) {
  return <div className={styles.header_wrapper}>
    <div className={styles.logo_wrapper}>
      <img className={styles.logo} src={logo} alt="WGS logo"/>
    </div>
    <div className={styles.title_wrapper}>
      <h1>A Glimpse into the Future</h1>
      <h2>Predictions for global development and innovation</h2>
    </div>
    <div className={styles.menu_wrapper}>
      <ul className={styles.nav}>
        {/* <li>About</li> */}
        <li onClick={openModal}>Help</li>
      </ul>
    </div>
  </div>
}