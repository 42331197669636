export const CATEGORIES = [{
  id: 0, 
  name: 'Govenrment Dot Com', 
  frontier: 'Governance', 
  buzz: 149000, 
  type: 'Innovation', 
  emoji: '🌐', 
}, 
{
  id: 1, 
  name: 'Triple Bottom Line', 
  frontier: 'Governance', 
  buzz: 1420000, 
  type: 'Innovation', 
  emoji: '♻️', 
}, 
{
  id: 2, 
  name: 'Collaborative Governance', 
  frontier: 'Governance', 
  buzz: 263000, 
  type: 'Development', 
  emoji: '🤝', 
}, 
{
  id: 3, 
  name: 'Data-Driven Policy', 
  frontier: 'Governance', 
  buzz: 37300, 
  type: 'Innovation', 
  emoji: '🔬', 
}, 
{
  id: 4, 
  name: 'Citizen-Consumers', 
  frontier: 'Governance', 
  buzz: 41900, 
  type: 'Innovation', 
  emoji: '🧠', 
}, 
{
  id: 5, 
  name: 'Cloud', 
  frontier: 'Governance', 
  buzz: 23900, 
  type: 'Innovation', 
  emoji: '☁️', 
}, 
{
  id: 6, 
  name: 'Data Transparency', 
  frontier: 'Governance', 
  buzz: 381000, 
  type: 'Development', 
  emoji: '👀'
}, 
{
  id: 7, 
  name: 'Consumerised Public Services', 
  frontier: 'Public Services', 
  buzz: 15, 
  type: 'Innovation', 
  emoji: '🛒', 
}, 
{
  id: 8, 
  name: 'Self-Service Public Services', 
  frontier: 'Public Services', 
  buzz: 4, 
  type: 'Innovation', 
  emoji: '🛠️', 
}, 
{
  id: 9, 
  name: 'Innovative Pricing Models', 
  frontier: 'Public Services', 
  buzz: 12600, 
  type: 'Development', 
  emoji: '🏷️'
}, 
{
  id: 10, 
  name: 'Global Migration', 
  frontier: 'Demographics', 
  buzz: 838000, 
  type: 'Development', 
  emoji: '✈️'
}, 
{
  id: 11, 
  name: 'Aging Population', 
  frontier: 'Demographics', 
  buzz: 4650000, 
  type: 'Development', 
  emoji: '🎂', 
}, 
{
  id: 12, 
  name: 'Population Decline in the West', 
  frontier: 'Demographics', 
  buzz: 1400000, 
  type: 'Development', 
  emoji: '📉'
}, 
{
  id: 13, 
  name: 'Population Growth in the East', 
  frontier: 'Demographics', 
  buzz: 1400000, 
  type: 'Development', 
  emoji: '📈'
}, 
{
  id: 14, 
  name: 'Socio-Economic Status of Women', 
  frontier: 'Demographics', 
  buzz: 7340000, 
  type: 'Development', 
  emoji: '🚺'
}, 
{
  id: 15, 
  name: 'Megacities', 
  frontier: 'Social Technology', 
  buzz: 2130000, 
  type: 'Development', 
  emoji: '🏙️'
}, 
{
  id: 16, 
  name: 'Smart Cities', 
  frontier: 'Social Technology', 
  buzz: 14500000, 
  type: 'Innovation', 
  emoji: '🚦'
}, 
{
  id: 17, 
  name: 'Sharing Economy', 
  frontier: 'Social Technology', 
  buzz: 5530000, 
  type: 'Development', 
  emoji: '💰'
}, 
{
  id: 18, 
  name: 'Driverless Vehicles', 
  frontier: 'Social Technology', 
  buzz: 510000, 
  type: 'Innovation', 
  emoji: '🚗'
}, 
{
  id: 19, 
  name: 'Efficient Social Media', 
  frontier: 'Social Technology', 
  buzz: 1130000000, 
  type: 'Innovation', 
  emoji: '📰'
}, 
{
  id: 20, 
  name: 'Defficient Social Media', 
  frontier: 'Social Technology', 
  buzz: 1130000000, 
  type: 'Innovation', 
  emoji: '⚠️'
}, 
{
  id: 21, 
  name: 'Blockchain', 
  frontier: 'Social Technology', 
  buzz: 188000000, 
  type: 'Innovation', 
  emoji: '🪙'
}, 
{
  id: 22, 
  name: 'Internet of Things', 
  frontier: 'Social Technology', 
  buzz: 309000000, 
  type: 'Innovation', 
  emoji: '💡'

}, 
{
  id: 23, 
  name: 'Infrastructure Bottlenecks', 
  frontier: 'Social Technology', 
  buzz: 112000, 
  type: 'Development', 
  emoji: '🚧'
}, 
{
  id: 24, 
  name: 'Patchwork Careers', 
  frontier: 'Economics', 
  buzz: 3730, 
  type: 'Development', 
  emoji: '💼'
}, 
{
  id: 25, 
  name: 'Income Inequalisation', 
  frontier: 'Economics', 
  buzz: 799000, 
  type: 'Development', 
  emoji: '⚖️'
}, 
{
  id: 26, 
  name: 'Digital Banking', 
  frontier: 'Economics', 
  buzz: 1220000, 
  type: 'Innovation', 
  emoji: '🏦'
}, 
{
  id: 27, 
  name: 'Wealth Movement from West to East', 
  frontier: 'Economics', 
  buzz: 18100, 
  type: 'Development', 
  emoji: '💱'
}, 
{
  id: 28, 
  name: 'Commercial AI', 
  frontier: 'Artificial Intelligence', 
  buzz: 28900, 
  type: 'Innovation', 
  emoji: '⌨️'
}, 
{
  id: 29, 
  name: 'Generative AI', 
  frontier: 'Artificial Intelligence', 
  buzz: 1, 
  type: 'Innovation', 
  emoji: '🎨'
}, 
{
  id: 30, 
  name: 'Machine Learning', 
  frontier: 'Artificial Intelligence', 
  buzz: 67000000, 
  type: 'Innovation', 
  emoji: '💻'
}, 
{
  id: 31, 
  name: 'Machine Reading & Comprehension', 
  frontier: 'Artificial Intelligence', 
  buzz: 175000, 
  type: 'Innovation', 
  emoji: '🗣️'
}, 
{
  id: 32, 
  name: 'Robotics', 
  frontier: 'Artificial Intelligence', 
  buzz: 953000, 
  type: 'Innovation', 
  emoji: '🤖'
}, 
{
  id: 33, 
  name: 'Climate Legislation', 
  frontier: 'Climate', 
  buzz: 143000, 
  type: 'Development', 
  emoji: '🌳'
}, 
{
  id: 34, 
  name: 'Smart Sustainable Energy', 
  frontier: 'Climate', 
  buzz: 5460000, 
  type: 'Innovation', 
  emoji: '🔋'
}, 
{
  id: 35, 
  name: 'Declining Natural Resources', 
  frontier: 'Climate', 
  buzz: 17500, 
  type: 'Development', 
  emoji: '🥀'
}, 
{
  id: 36, 
  name: 'Molecular Agriculture', 
  frontier: 'Climate', 
  buzz: 12200, 
  type: 'Innovation', 
  emoji: '🍎'
}, 
{
  id: 37, 
  name: 'Industrial Sector Changes', 
  frontier: 'Climate', 
  buzz: 6430, 
  type: 'Development', 
  emoji: '🏭'
}, 
{
  id: 38, 
  name: 'Climate Engineering', 
  frontier: 'Climate', 
  buzz: 459000, 
  type: 'Development', 
  emoji: '🌎'
}, 
];

export const PREDICTIONS = [
  {
    id: 0, 
    categoryId: 0, 
    period: 'Now', 
    text: 'Governments use social media and other data-driven media to engage citizens.', 
  }, 
  {
    id: 1, 
    categoryId: 0, 
    period: '2025', 
    text: 'Social media provides constant stream of localised data for governments to analyse sentiment & track the effectiveness of their services.', 
  }, 
  {
    id: 2, 
    categoryId: 0, 
    period: '2030', 
    text: 'Beta Government. Policy makers apply the \'agile software development model\' to policy. Prototypes, pilots, trials, etc.', 
  }, 
  {
    id: 3, 
    categoryId: 0, 
    period: '2040', 
    text: 'Governments build platforms, hold partners accountable, manage crowdsourced campaigns etc.', 
  }, 
  {
    id: 4, 
    categoryId: 0, 
    period: '2050', 
    text: 'Operational shortcomings addressed using tech and citizen input. One online space for all services, and one physical space in each locale, streamlines govt.', 
  }, 
  {
    id: 5, 
    categoryId: 0, 
    period: 'Beyond', 
    text: 'Alternatives to GDP take hold. Holistic yardsticks for national progress, e.g. sustainability, health, inclusion, personal freedom... replace GDP as primary measure.', 
  }, 
  {
    id: 6, 
    categoryId: 1, 
    period: 'Now', 
    text: 'ESG accounting commonplace. Divestment of fossil fuel assets encouraged.', 
  }, 
  {
    id: 7, 
    categoryId: 1, 
    period: '2025', 
    text: 'Government encourages \'triple-bottom line\' businesses - focus on social and environmental goals in addition to financial ones. Multi-trillion-dollar capital markets emerge for social outcomes', 
  }, 
  {
    id: 8, 
    categoryId: 1, 
    period: '2030', 
    text: 'Triple Sector State. Government, business, citizens collaborate to shape the state.', 
  }, 
  {
    id: 9, 
    categoryId: 1, 
    period: '2040', 
    text: 'Triple Sector Democracy. Citizens work at solving problems through social, mobile and cloud tech, e.g. micro-tasking, peer-to-peer models.', 
  }, 
  {
    id: 10, 
    categoryId: 2, 
    period: 'Now', 
    text: 'Government works with NGOs, corporations, social enterprises and citizens - and other non-state problem solvers.', 
  }, 
  {
    id: 11, 
    categoryId: 2, 
    period: '2025', 
    text: 'Reduced, optimised civil service attracts top talent. Return of \'parastatals\', linking of political will & private resources.', 
  }, 
  {
    id: 12, 
    categoryId: 2, 
    period: '2030', 
    text: 'Physical and online innovation spaces - government works with citizens and companies to come up with new ideas and crowd-source policies.', 
  }, 
  {
    id: 13, 
    categoryId: 2, 
    period: '2040', 
    text: '\'Patient Capitalism\' Long-term investment in major infrastructure projects bring government and businesses together.', 
  }, 
  {
    id: 14, 
    categoryId: 2, 
    period: '2050', 
    text: 'Demarchy: rulers are randomly chosen from a pool of selected decision-makers. Term coined by philosopher John Burnheim', 
  }, 
  {
    id: 15, 
    categoryId: 2, 
    period: 'Beyond', 
    text: 'Government allows cities a more active & empowered role - more funding & more authority.', 
  }, 
  {
    id: 16, 
    categoryId: 3, 
    period: 'Now', 
    text: 'Big data improves tracking of performance and outcomes, allowing regulators to focus more on achieving goals and less on processes.', 
  }, 
  {
    id: 17, 
    categoryId: 3, 
    period: '2025', 
    text: 'Advanced algorithms provide rich, live data that informs government decision-making.', 
  }, 
  {
    id: 18, 
    categoryId: 3, 
    period: '2030', 
    text: 'Preemptive Government. Data used to generate predictive models for government. Resources shifted from reaction to prevention.', 
  }, 
  {
    id: 19, 
    categoryId: 3, 
    period: '2050', 
    text: 'Cyberocracy: emphasises symbolic, cultural, psychological aspects of policymaking. Information unbound, unchannelled. Eventually leads to AI civil service, or even AI head of state.', 
  }, 
  {
    id: 20, 
    categoryId: 4, 
    period: 'Now', 
    text: 'Staffing cloud. Permanent employees act as consultants. Flexible teams formed to focus on specific projects.', 
  }, 
  {
    id: 21, 
    categoryId: 4, 
    period: '2025', 
    text: 'Co-creation means people are more invested in government, and engagement increases. New opportunities for national and global problem solving through mass collaboration on vast data sets.', 
  }, 
  {
    id: 22, 
    categoryId: 4, 
    period: '2030', 
    text: 'Governments work with citizens to address challenges, e.g. climate change.', 
  }, 
  {
    id: 23, 
    categoryId: 4, 
    period: '2050', 
    text: 'Noocracy: government of the wise, global brain, \'syntellect\', hive-mind. Stems from Plato\'s \'government of the wise\' concept. Popularised by Vladimir Vernadsky.', 
  }, 
  {
    id: 24, 
    categoryId: 5, 
    period: 'Now', 
    text: 'Governments can faces challenges in delivering public services - reaching both the hyper-connected and the poor, elderly, deeply rural segments who aren\'t connected.', 
  }, 
  {
    id: 25, 
    categoryId: 5, 
    period: '2030', 
    text: 'Collaborative Cloud. Interactive cloud platforms faciliate engagement by citizen-consumers.', 
  }, 
  {
    id: 26, 
    categoryId: 5, 
    period: '2050', 
    text: 'Cloud States. Extra-territorial initiatives, cross-border connectivity. State boundaries blurred & dissolve as interaction, debate and creation spans borders.', 
  }, 
  {
    id: 27, 
    categoryId: 6, 
    period: 'Now', 
    text: 'Policies and norms around privacy in age of data breaches & IoT becomes a regulatory and ethical issue for government.', 
  }, 
  {
    id: 28, 
    categoryId: 6, 
    period: '2025', 
    text: 'Targeted transparency. Public demands government and corporate disclosure. Easily-accessible data is made available.', 
  }, 
  {
    id: 29, 
    categoryId: 6, 
    period: '2030', 
    text: 'Radical transparency - maximally increased openness in process and data access - becomes the norm.', 
  }, 
  {
    id: 30, 
    categoryId: 6, 
    period: 'Beyond', 
    text: 'Radical transparency becomes the law', 
  }, 
  {
    id: 31, 
    categoryId: 7, 
    period: 'Now', 
    text: 'Citizens are more aware of their rights, have better access to info and higher expectations of services.', 
  }, 
  {
    id: 32, 
    categoryId: 7, 
    period: '2025', 
    text: 'Franchised Services. Business style models for public services \x03- especially utilities, education, \x03healthcare - become the \x03international norm.', 
  }, 
  {
    id: 33, 
    categoryId: 7, 
    period: '2030', 
    text: 'Agile Services. Public services follow the path of startups like Uber - digital, user-friendly, agile - satisfies demand.', 
  }, 
  {
    id: 34, 
    categoryId: 7, 
    period: '2050', 
    text: 'Public Service EcoSystem. Police and other authorities treated \x03like any other service providers - i.e. \x03can be rejected and an alternative \x03sought. Technology assists with this \x03(e.g. police bodycams).', 
  }, 
  {
    id: 35, 
    categoryId: 8, 
    period: 'Now', 
    text: 'Medical tourism, outsourcing service delivery, best-in-class governments help other governments, etc.', 
  }, 
  {
    id: 36, 
    categoryId: 8, 
    period: '2025', 
    text: 'Fully technology-enabled self-service of government services (vehicle registration, income tax etc) expanding.', 
  }, 
  {
    id: 37, 
    categoryId: 8, 
    period: '2030', 
    text: 'Hyperlocal services. Neighbourhoods crowdfund their own needs. Services like higher education partially released from government.', 
  }, 
  {
    id: 38, 
    categoryId: 8, 
    period: '2040', 
    text: 'public sector organisations transform from big, all-in-one giants to small, flexible, purpose-driven entities.', 
  }, 
  {
    id: 39, 
    categoryId: 8, 
    period: '2050', 
    text: 'Operational shortcomings addressed using tech and citizen input. One online space for all services, and one physical space in each locale streamlines govt.', 
  }, 
  {
    id: 40, 
    categoryId: 9, 
    period: '2025', 
    text: 'Pay-as-you-go systems for public services, which helps to balance demand and provision. Fuel tax replaced with mileage fees.', 
  }, 
  {
    id: 41, 
    categoryId: 9, 
    period: '2030', 
    text: 'Potential small direct payments using mobile payment apps, for use of public services, e.g. parks, hospitals.', 
  }, 
  {
    id: 42, 
    categoryId: 10, 
    period: 'Now', 
    text: 'Global nomadic citizens, using sharing economy, locate themselves anywhere in the world', 
  }, 
  {
    id: 43, 
    categoryId: 10, 
    period: '2025', 
    text: 'Immigration policies are somewhat relaxed as a result of globalisation, improved education and shortage of local talent. Refugees further increase as resources grow scarce in parts of the world.', 
  }, 
  {
    id: 44, 
    categoryId: 10, 
    period: '2050', 
    text: 'Major cities will increase in population by 20.6% as urbanisation continues (by contrast, non-cities population will increase by 12.3% with general population growth).', 
  }, 
  {
    id: 45, 
    categoryId: 11, 
    period: 'Now', 
    text: 'Policies have to take into account an increasingly large dependent segment. Pension reforms to reduce intergenerational inequality, e.g. raising the age of retirement, changing eligibility etc.', 
  }, 
  {
    id: 46, 
    categoryId: 11, 
    period: '2025', 
    text: 'Aging workforces and lack of skilled technical talent drives freelance economy. Governments work with companies and organisations to address skills gap - a nation\'s talent levels drive their economy.', 
  }, 
  {
    id: 47, 
    categoryId: 11, 
    period: '2030', 
    text: 'Population aging pushes healthcare & pension spending up 4.4% of GDP by 2030. 13% of the world population is 65+ (versus 8% today)', 
  }, 
  {
    id: 48, 
    categoryId: 11, 
    period: '2040', 
    text: 'Aging population. Longer lifespans, higher IQ, restoration of hearing and vision etc, all pose regulatory and ethical challenges. Older employed saturate the jobs market.', 
  }, 
  {
    id: 49, 
    categoryId: 11, 
    period: '2050', 
    text: 'Aged Population. Majority of the adult population now \x03over 65 has substantial effect\non governmental & social policy and make-up.', 
  }, 
  {
    id: 50, 
    categoryId: 11, 
    period: 'Beyond', 
    text: 'Gerontocracy: The ageing population wields all the power, the young live as outsiders.', 
  }, 
  {
    id: 51, 
    categoryId: 12, 
    period: '2030', 
    text: 'Decline of the working age population impacts economic policy.', 
  }, 
  {
    id: 52, 
    categoryId: 13, 
    period: 'Now', 
    text: 'Nations with young populations, e.g. India, see economic growth stem from large working-age segment.', 
  }, 
  {
    id: 53, 
    categoryId: 13, 
    period: '2030', 
    text: 'By 2030 China and India will account for 35% of world population', 
  }, 
  {
    id: 54, 
    categoryId: 14, 
    period: 'Now', 
    text: 'Women expand the global workforce, influencing political and economic policy worldwide.', 
  }, 
  {
    id: 55, 
    categoryId: 15, 
    period: 'Now', 
    text: 'Around 7% of people live in cities with 8-figure populations (10\'s of millions per city)', 
  }, 
  {
    id: 56, 
    categoryId: 15, 
    period: '2025', 
    text: 'Urbanisation and city growth creates megacities - near-autonomous hubs where initatives can be tested. No. of megacities (pop 10m+) will increase from 20 today to 37 in 2025', 
  }, 
  {
    id: 57, 
    categoryId: 15, 
    period: '2030', 
    text: 'Slums continue to grow - providing infrastructure and amenities to poverty-stricken urban areas become important policy points. Number of people living in city slums to double by 2030 (from 1bn to 2bn)', 
  }, 
  {
    id: 58, 
    categoryId: 15, 
    period: 'Beyond', 
    text: 'Seasteading: autonomous modular city states floating on oceans.', 
  }, 
  {
    id: 59, 
    categoryId: 16, 
    period: '2025', 
    text: 'Smart city applications deployed in at least 50 cities globally. Key sectors: security, utilities, healthcare, & economic development, housing & community.', 
  }, 
  {
    id: 60, 
    categoryId: 16, 
    period: '2030', 
    text: 'Major tech companies build experimental smart cities, which include things like heated roads, modular housing, clean energy, autonomous vehicles, high-speed networks etc.', 
  }, 
  {
    id: 61, 
    categoryId: 16, 
    period: '2040', 
    text: 'The streets will speak, literally. Offering information and services.', 
  }, 
  {
    id: 62, 
    categoryId: 16, 
    period: '2050', 
    text: 'Cost of living lowered, crime reduced 30-40%, first responders arrive 2-17mins faster, commute times cut 15-20%, impact of air pollution reduced 8-15%, emissions lowered 10-15%, solid waste & water use cut, public report problems & weigh in on planning, neighbours are linked, 1-3% boost in employment.', 
  }, 
  {
    id: 63, 
    categoryId: 17, 
    period: 'Now', 
    text: 'Rideshares, food sharing, skills sharing etc., changes the way cities operate and reduces pressure on government services and infrastructure.', 
  }, 
  {
    id: 64, 
    categoryId: 17, 
    period: '2050', 
    text: 'Reputation Economy. Ebay style ratings of workers & employers \x03creates a technology-mediated\nmarketplace where reputation is the singular currency.', 
  }, 
  {
    id: 65, 
    categoryId: 18, 
    period: 'Now', 
    text: 'Driverless cars tested on roads in the UK, the US, & China.', 
  }, 
  {
    id: 66, 
    categoryId: 18, 
    period: '2025', 
    text: 'Initial prototypes and small scale services introduced.', 
  }, 
  {
    id: 67, 
    categoryId: 18, 
    period: '2030', 
    text: 'Driverless vehicles successfully emerge, using a combination of deep machine learning & computer vision for object recognition.', 
  }, 
  {
    id: 68, 
    categoryId: 18, 
    period: '2050', 
    text: 'Driverless vehicles unbiquitous. High-value real estate currently used for parking freed up for other uses.', 
  }, 
  {
    id: 69, 
    categoryId: 19, 
    period: 'Now', 
    text: 'Social media used increasingly to organize protests, shape cultural narratives, and pressurise governments and policy.', 
  }, 
  {
    id: 70, 
    categoryId: 20, 
    period: 'Now', 
    text: 'Social media used increasingly to spread and amplify dis- and mis-information to confuse, divide and polarise audiences', 
  }, 
  {
    id: 71, 
    categoryId: 21, 
    period: 'Now', 
    text: 'Despite substantial shocks and reputational collapse, blockchain tech stands as a developing and credible addition finanicial and trading services.', 
  }, 
  {
    id: 72, 
    categoryId: 21, 
    period: '2030', 
    text: 'Blockchain acts a bulkwalk against cybercrime. Decentralized, distributed systems creates more robust defenses against data breaches.', 
  }, 
  {
    id: 73, 
    categoryId: 21, 
    period: '2040', 
    text: 'Hospitals and other healthcare organizations create centralized and secure blockchain databases, storing medical records and share only with patients and authorized custodians.', 
  }, 
  {
    id: 74, 
    categoryId: 21, 
    period: '2050', 
    text: 'Enhanced next-gen blockchain reduced bureaucracy increases security, efficiency and transparency in government. Welfare and unemployment benefits more easily verified and distributed. Electoral votes could be counted and verified for legitimacy.', 
  }, 
  {
    id: 75, 
    categoryId: 22, 
    period: 'Now', 
    text: 'Smart objects increasingly and effectively used in industry and consumer settings.', 
  }, 
  {
    id: 76, 
    categoryId: 22, 
    period: '2030', 
    text: 'Governments attempt to integrate IoT tech, using data to improve healthcare, transport, security, defence, infrastructure etc.', 
  }, 
  {
    id: 77, 
    categoryId: 22, 
    period: '2040', 
    text: 'Connecting objects with infrastructure (e.g. cars and roads) drastically reduce traffic accidents and deaths. And empower rapid disaster response for natural disasters.', 
  }, 
  {
    id: 78, 
    categoryId: 23, 
    period: 'Now', 
    text: 'Many governments need to modernise existing infrastructure - but struggle with cost and vision.', 
  }, 
  {
    id: 79, 
    categoryId: 23, 
    period: '2030', 
    text: 'Govt investment in connectivity tech to encourage flexible working arrangements, e.g. telecommuting, to reduce pressure on congested cities & overloaded transport systems.', 
  }, 
  {
    id: 80, 
    categoryId: 24, 
    period: 'Now', 
    text: 'More micro-entrepreneurs (e.g. Etsy sellers, Uber drivers). Regulation & tax upheavals to support tech-informed class of individual workers.', 
  }, 
  {
    id: 81, 
    categoryId: 24, 
    period: '2025', 
    text: 'Government workforce expanded to include partnership talent, borrowed talent, freelance talent, open-source talent, home-working talent, gig talent.', 
  }, 
  {
    id: 82, 
    categoryId: 24, 
    period: '2030', 
    text: 'Re-learning Economy. National competitiveness relies on ability to continually retrain workers to keep up with changing environment & technologies.', 
  }, 
  {
    id: 83, 
    categoryId: 25, 
    period: 'Now', 
    text: 'Global income inequality rising. Governments prioritise access to healthcare, education, etc., for the poorest segments.', 
  }, 
  {
    id: 84, 
    categoryId: 25, 
    period: '2025', 
    text: 'Performance-related salaries. Payment-for-results models become mainstream, shifts financial risk from government to investors.', 
  }, 
  {
    id: 85, 
    categoryId: 25, 
    period: '2030', 
    text: 'Universal Basic Income. As robotics and automation replace jobs, nations experiment with giving their citizens flat-rate salaries, instead of benefits.', 
  }, 
  {
    id: 86, 
    categoryId: 25, 
    period: '2040', 
    text: 'Expanding middle class. 60% of the global population are middle class, up from 27% in 2009. Knowledge workers dominate the market. But who performs all the manual labour?', 
  }, 
  {
    id: 87, 
    categoryId: 26, 
    period: 'Now', 
    text: 'Lightweight financial and banking services on offer, without the need to maintain heavy banking infrastructure nor comply with complex regulations. Traditional banking under threat.', 
  }, 
  {
    id: 88, 
    categoryId: 26, 
    period: '2030', 
    text: 'digital currencies are fully mainstream. Digital theft protection is a primary concern for governments.', 
  }, 
  {
    id: 89, 
    categoryId: 27, 
    period: '2030', 
    text: 'Tipping Point. Developing countries account for 57% of global GDP.', 
  }, 
  {
    id: 90, 
    categoryId: 27, 
    period: '2040', 
    text: 'Economic power and wealth moves from west to east, shifting global politics.', 
  }, 
  {
    id: 91, 
    categoryId: 27, 
    period: '2050', 
    text: 'China and India account for 25% of global GDP', 
  }, 
  {
    id: 92, 
    categoryId: 28, 
    period: 'Now', 
    text: 'Passive AI. Used for product development, automating rote tasks, improving services, & monitoring employees.', 
  }, 
  {
    id: 93, 
    categoryId: 28, 
    period: '2025', 
    text: 'AI Assistants. Chatbots & digital assistants interact with job candidates, identify and score video interviews, and understand the sentiment of engagement surveys.', 
  }, 
  {
    id: 94, 
    categoryId: 28, 
    period: '2030', 
    text: 'AI Hiring. Increasingly used in HR. Analytics tools are intelligently selecting candidates, identifying employees’ career options, and coaching managers on improving their leadership skills.', 
  }, 
  {
    id: 95, 
    categoryId: 29, 
    period: 'Now', 
    text: 'Cambrian explosion of generative AI services democratize content and visual media creation, flooding the market and the group imagination with new resources and possibilities.', 
  }, 
  {
    id: 96, 
    categoryId: 29, 
    period: '2025', 
    text: 'Generative AI enhanced careers both boost and reduce creative, academic, craft and content careers.', 
  }, 
  {
    id: 97, 
    categoryId: 29, 
    period: '2030', 
    text: 'Next generation generative AI\'s can infer depth, create moving images and animations, and link to accessory tech like 3D printing to produce objects, art works and products in minutes.', 
  }, 
  {
    id: 98, 
    categoryId: 30, 
    period: 'Now', 
    text: 'Machine learning is being used gain competitive insights in financial services, healthcare, oil & gas, marketing & sales, & transport.', 
  }, 
  {
    id: 99, 
    categoryId: 30, 
    period: '2030', 
    text: 'Intelligent, learning machines mimick human thought process. Used to automate administrative tasks, assist in problem solving etc.', 
  }, 
  {
    id: 100, 
    categoryId: 30, 
    period: '2040', 
    text: 'Security cameras conduct marketing research on passing consumers. Healthcare scanners help technicians. Guided surgeries are are the norm. Farm equipment increasingly autonomous.', 
  }, 
  {
    id: 101, 
    categoryId: 30, 
    period: '2050', 
    text: 'Machine learning + geonomics results in highly individualized medical treatments.', 
  }, 
  {
    id: 102, 
    categoryId: 31, 
    period: 'Now', 
    text: 'AI\'s trained of trillions of English documents can generate text in any style in any text and answer questions and analyse the content.', 
  }, 
  {
    id: 103, 
    categoryId: 31, 
    period: '2030', 
    text: 'Automated multi-lingual chat AI\'s provide automated customer and information services worldwide.', 
  }, 
  {
    id: 104, 
    categoryId: 32, 
    period: 'Now', 
    text: 'Hundreds of thousands of robots and collaborative \'co-bots\' already in operation in retail and manufacturing.', 
  }, 
  {
    id: 105, 
    categoryId: 32, 
    period: '2030', 
    text: 'Robots which can read emotions and converse will become our friends.', 
  }, 
  {
    id: 106, 
    categoryId: 32, 
    period: '2040', 
    text: 'Home robots help elders with everyday tasks, predict when they need to use the toilet, detect falls & more.', 
  }, 
  {
    id: 107, 
    categoryId: 32, 
    period: '2050', 
    text: '800+ million global workers are replaced by robots.', 
  }, 
  {
    id: 108, 
    categoryId: 33, 
    period: 'Now', 
    text: 'Supranational and national bodies further legislate on emissions. Single use plastic bans, new ecological building standards', 
  }, 
  {
    id: 109, 
    categoryId: 33, 
    period: '2030', 
    text: 'Adaptive GAction. Policy on climate change switches from ineffective top-down system of IPCC initiatives to a bottom-up approach, engaging all levels of power and acting locally.', 
  }, 
  {
    id: 110, 
    categoryId: 33, 
    period: '2040', 
    text: 'The First \'netzero\' commitments are enacted by governments & corporations.', 
  }, 
  {
    id: 111, 
    categoryId: 33, 
    period: '2050', 
    text: '\'Growth within limits\' is the inward developmental mindset necessary to reduce our ecological footprint.', 
  }, 
  {
    id: 112, 
    categoryId: 33, 
    period: 'Beyond', 
    text: 'Businesses cannot operate unless they are fully sustainable at their core.', 
  }, 
  {
    id: 113, 
    categoryId: 34, 
    period: 'Now', 
    text: 'The drive to pioneer efficient and \x03affordable energy solutions drives \x03innovation in the energy, technology \x03and industry sectors.', 
  }, 
  {
    id: 114, 
    categoryId: 34, 
    period: '2030', 
    text: 'Passive climate control systems, innovative water/sewage treatment flows, green building materials and high-performance recycling technology', 
  }, 
  {
    id: 115, 
    categoryId: 34, 
    period: '2040', 
    text: 'The sharing economy meets renewables, propelling the growth of community solar, crowd-funding, and other forms of shared participation in renewable energy deployment.', 
  }, 
  {
    id: 116, 
    categoryId: 34, 
    period: '2050', 
    text: 'A full-scale “grid independence” could be underway with smart- and micro-grids able to store and modulate the viable energy output from renewables.', 
  }, 
  {
    id: 117, 
    categoryId: 34, 
    period: 'Beyond', 
    text: 'Global electrification of power systems progressing.', 
  }, 
  {
    id: 118, 
    categoryId: 35, 
    period: 'Now', 
    text: 'Top priority area for WEF, UN etc.', 
  }, 
  {
    id: 119, 
    categoryId: 35, 
    period: '2030', 
    text: 'Food prices predicted to double by 2030. Potential 40% global gap between water supply and demand by 2030', 
  }, 
  {
    id: 120, 
    categoryId: 35, 
    period: '2050', 
    text: 'Military-led responses to natural disasters become the norm.', 
  }, 
  {
    id: 121, 
    categoryId: 36, 
    period: 'Now', 
    text: 'Veganism increasing in global population as ecological costs of meat industry become more apparent.', 
  }, 
  {
    id: 122, 
    categoryId: 36, 
    period: '2025', 
    text: 'Lab-grown meat becomes affordable and begins to become socially acceptable.', 
  }, 
  {
    id: 123, 
    categoryId: 36, 
    period: '2030', 
    text: 'Almost all meat-based foods replacable by high tech plant formulations and lab-cultured meats, mimicking the taste, smell, and texture.', 
  }, 
  {
    id: 124, 
    categoryId: 37, 
    period: '2030', 
    text: 'Circular economic principles become more primary. Recycling, recovery, re-use of material the norm, rather than just \'waste\'.', 
  }, 
  {
    id: 125, 
    categoryId: 37, 
    period: '2040', 
    text: 'Shift from industry-based sectors to issue-based sectors, e.g. creative industries, agri-food and water, wellbeing and welfare.', 
  }, 
  {
    id: 126, 
    categoryId: 38, 
    period: 'Now', 
    text: 'Proposals for engineering carbon drawdown systems are scoped and budgeted.', 
  }, 
  {
    id: 127, 
    categoryId: 38, 
    period: '2030', 
    text: 'First prototypes for atmospheric sequestration of carbon dioxide are in operation', 
  }, 
  {
    id: 128, 
    categoryId: 38, 
    period: 'Beyond', 
    text: 'Carbon capture, land weathering, ocean fertisilation - massive, costly bio-engineering projects implemented on a global scale - costing trillions of dollars, required to drawdown gigatons of surplus carbon dioxide from the atmosphere.', 
  }, 
];

// TODO: 'Generative AI has no buzz or type data'
