import React, { useState } from 'react';
import { Header } from "./components/Header"
import { Main } from './components/Main'
import { Modal } from './components/Modal'
import styles from './App.module.scss'

function App() {
  const [hideModal, setHideModal] = useState(false)
  return (
    <div className={styles.app_wrapper}>
      <Header openModal={() => setHideModal(false)}/>
      <Main />
      <Modal closeModal={() => setHideModal(true)} showModal={!hideModal}/>
    </div>
  );
}

export default App;
